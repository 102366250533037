.navbar {
    background-color: transparent;
    padding: 0.5rem 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 90;
}

.navbar-wrapper {
    display: flex;
    height: 10vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-left {
    flex: 1;
    padding-left: 8%;
    padding-right: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.navbar-logo-button {
    height: 100%;
    object-fit: contain;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.navbar-logo {
    height: 100%;
}

.navbar-logo:hover {
    filter: brightness(120%) sepia(30) saturate(120) hue-rotate(150deg);
}

.socials-1, .socials-2, .socials-3, .socials-4 {
    cursor: pointer;
    flex: 1;
    height: 30%;
    width: 30%;
    object-fit: contain;
    color: white;
}

.socials-1:hover {
    filter: brightness(70%) sepia(60) saturate(50) hue-rotate(15deg);
}

.socials-2:hover {
    filter: brightness(100%) sepia(10) saturate(80) hue-rotate(-10deg);
}

.socials-3:hover {
    filter: brightness(20%) sepia(60) saturate(50) hue-rotate(-15deg);
}

.socials-4:hover {
    filter: brightness(90%) sepia(60) saturate(30) hue-rotate(55deg);
}

.navbar-right {
    flex: 2;
    display: flex;
    align-items: normal;
    justify-content: flex-end;
    padding-right: 9%;
}

.navbar-right-item {
    color: #FFF;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 5%;
    cursor: pointer;
    font-size: 3vh;
}

.navbar-right-item.onscreen {
    color: rgb(236, 213, 0);
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #222222);
    align-items: center;
    justify-content: space-evenly;
    padding-right: 5%;
    cursor: pointer;
    font-size: 3vh;
}

.navbar-blog-button {
    display: inline-block;
    outline: 0.1vh solid rgb(138, 138, 138);
    margin-right: 1vh;
    margin-bottom: 0.5vh;
    background-color: rgb(1, 105, 100);
    border-radius: 0.5vh;
    color: rgb(206, 206, 206);
    align-items: center;
    justify-content: space-evenly;
    padding-inline: 2vh;
    cursor: pointer;
    font-size: 3vh;
}

.navbar-blog-button:hover {
    background-color: rgb(65, 22, 22);
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #000000);
}

.navbar-blog-button.hidden {
    visibility: hidden;
}


@media screen and (orientation:portrait) {
    .navbar-right {
        padding-left: 5%;
        align-items: center;
        justify-content: flex-start;
    }

    .navbar-logo-button {
        padding-right: 5%;
    }
    
    .navbar-logo {
        height: 10vh;
        align-items: center;
        justify-content: center;
    }

    .navbar-wrapper {
        overflow: auto;
        white-space: nowrap;
        mask-image: linear-gradient(0.25turn, transparent, black 30%, black 70%, transparent 100%);
        -webkit-mask-image: linear-gradient(0.25turn, transparent, black 30%, black 80%, transparent 100%);
    }

    ::-webkit-scrollbar {
        display: none;
    }
}