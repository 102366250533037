@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');

.intro {
    height: 100vh;
    background-color: rgb(44, 44, 44);
    color: rgb(235, 235, 235);
    position: relative;
}

.empty-space-for-navbar {
    height: 10vh;
    top: 0;
}

.intro-wrapper {
    display: flex;
    height: 80vh;
}

.intro-left {
    flex: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-left-wrapper {
    padding: 15%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.intro-left-name-hi {
    font-size: 5vh;
}

.intro-left-name-grant {
    width: 75%;
    font-size: 10vh;
    overflow: hidden;
    border-right: .15em solid teal;
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #000000);
    white-space: nowrap;
    color: rgb(236, 213, 0);
    /* font-family: 'PT Mono', monospace;   */
    /* letter-spacing: .15em; Adjust as needed */
    animation: 
        typing 1.5s steps(25, end),
        blink-caret .75s step-end infinite;
}

@keyframes typing {
    from { width: 0 }
    to { width: 75% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: teal; }
}

.intro-left-roles-wrapper {
    word-wrap: break-word;
    font-size: 5vh;
    height: 7vh;
    overflow: hidden;
}

.intro-left-im {
    float: left;
    margin-right: 1.2vh;
}

.intro-left-roles-marquee {
    float: left;
    height: 100%;
    animation: move 6s ease-in-out infinite alternate;
}

@keyframes move {
    50% {
        transform: translateY(-7.5vh);
    }
    100% {
        transform: translateY(-15vh);
    }
}

.intro-left-role {
    color: rgb(236, 213, 0);
    padding-bottom: 1vh;
}

.intro-left-description {
    font-size: 2.5vh;
    word-break: break-word;
}

.intro-right {
    flex: 4;
    display: flex;
}

.intro-right-photo {
    background-image: url("../../img/intro/intro_photo.jpg");
    background-repeat: no-repeat;
    flex: 20;
    width: 90%;
    height: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 1vh 3vh rgb(44, 44, 44) inset;
}

.intro-right-padding {
    flex: 4;
}


@media screen and (orientation:portrait) {    
    .intro-wrapper {
        height: 80vh;
        flex-direction: column-reverse;
    }

    .intro-left {
        flex: 2;
    }

    .intro-left-wrapper {
        padding: 10%;
        height: 100%;
        justify-content: space-evenly;
    }

    .intro-left-name-hi {
        font-size: 25px;
    }

    .intro-left-name-grant {
        font-size: 40px;
    }

    .intro-left-roles-wrapper {
        font-size: 20px;
        height: 28px;
    }

    .intro-left-description {
        font-size: 15px;
    }

    @keyframes move {
        50% {
            transform: translateY(-30px);
        }
        100% {
            transform: translateY(-60px);
        }
    }

    .intro-left-role {
        padding-bottom: 4px;
    }

    .intro-right {
        flex: 4;
    }

    .intro-right-padding {
        flex: 0;
    }
}