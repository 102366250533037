.education {
    height: 100vh;
    background-color: rgb(4, 65, 73);
    color: white;
    position: relative;
}

.empty-space-for-navbar {
    height: 10vh;
    top: 0;
}

.education-wrapper {
    display: flex;
    height: 90vh;
}

.education-left {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.education-left-wrapper {
    margin-left: 10%;
    padding: 8%;
    height: 70%;
    flex-direction: column;
    justify-content: space-between;
}

.education-left-preface {
    font-size: 4vh;
    padding-bottom: 2vh;
}

.soc-logo {
    width: 60%;
}

.education-left-degree-1 {
    font-size: 3.5vh;
    padding-top: 10vh;
}

.education-left-degree-2 {
    white-space: nowrap;
    overflow-x: auto;
}

.education-left-degree-2 h3 {
    font-size: 3.5vh;
    color: rgb(0, 236, 0);
    display: inline-block;
}

.education-left-degree-2 p {
    padding-left: 1vh;
    display: inline-block;
    height: 100%;
    font-size: 1.8vh;
    font-style: italic;
    color: white;
}

.education-left-awards {
    font-size: 2.2vh;
    padding-top: 3.5vh;
    white-space: pre;
}

.education-right {
    flex: 2;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
}

.education-right-wrapper {
    height: 70%;
    flex-direction: column;
    justify-content: space-between;
}

.education-right-preface {
    font-size: 3.5vh;
}

.education-right-spec {
    font-size: 3.5vh;
    padding-top: 5vh;
}

.education-right-spec-card-front {
    display: inline-block;
    border-radius: 1vh;
    background: rgb(1, 99, 33);
    padding: 1.7vh;
    width: 40vh;
    height: 15vh;
    margin: auto;
    vertical-align: middle;
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #000000);
}

.education-right-spec-name {
    color: white;
    font-size: 3.5vh;
    display: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}

.education-right-spec-subtitle {
    font-size: 2vh;
    font-style: italic;
}

.education-right-spec-card-back {
    display: inline-block;
    border-radius: 1vh;
    background: rgb(1, 99, 33);
    padding: 1.7vh;
    width: 40vh;
    height: 15vh;
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #000000);
}

.education-right-spec-courses {
    white-space: pre;
    font-size: 1.75vh;
}

.education-right-spec-courses-header {
    padding-bottom: 0.8vh;
    font-size: 2vh;
}

a:link {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:visited {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media screen and (orientation:portrait) {
    .education-wrapper {
        flex-direction: column;
        height: 90vh;
    }

    .education-left {
        flex: 1;
    }

    .education-left-wrapper {
        margin-left: 0;
        height: 80%;
    }

    .education-left-preface {
        font-size: 15px;
        padding-bottom: 10px;
    }

    .education-left-degree-1 {
        font-size: 13px;
        padding-top: 10px;
    }

    .education-left-degree-2 {
        white-space: normal;
    }

    .education-left-degree-2 h3 {
        font-size: 13px;
        display: block;
    }

    .education-left-degree-2 p {
        font-size: 10px;
        display: block;
        padding-left: 0;
    }

    .education-left-awards {
        font-size: 11px;
        overflow: hidden;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
    }

    .education-right {
        flex: 3;
    }

    .education-right-wrapper {
        height: 100%;
        padding: 8%;
    }

    .education-right-preface {
        font-size: 15px;
    }

    .education-right-spec {
        font-size: 15px;
        padding-top: 20px;
        align-items: center;
        justify-content: center;
    }

    .education-right-spec-card-front {
        width: 75vw;
        height: 13vh;
    }

    .education-right-spec-name {
        font-size: 20px;
    }

    .education-right-spec-subtitle {
        font-size: 13px;
    }

    .education-right-spec-card-back {
        width: 75vw;
        height: 13vh;
    }

    .education-right-spec-courses {
        font-size: 11px;
    }
    
    .education-right-spec-courses-header {
        padding-bottom: 2px;
        font-size: 12px;
    }
}