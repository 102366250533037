.project-card {
    height: 70vh;
    width: 100%;
    background-color: rgb(70, 62, 94);
    position: relative;
    display: flex;
}

.project-card-banner {
    flex: 2;
    width: 100%;
    top: 0;
    left: 0;
}

.project-card-banner-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: left;
}

.project-card-info {
    flex: 1;
    width: 100%;
    text-align: left;
    overflow-y: scroll;
    z-index: 90;
}

.project-card-category {
    display: inline-block;
    border-radius: 2.5vh;
    background: #5d9114;
    padding-inline: 1vh;
    margin-top: 5vh;
    margin-left: 4vh;
    margin-right: 4vh;
}

.project-card-title {
    margin-left: 4vh;
    margin-right: 4vh;
    font-size: 4vh;
}

.project-card-subtitle {
    color: rgb(236, 213, 0);
    margin-left: 4vh;
    margin-right: 4vh;
    font-style: italic;
    margin-bottom: 4vh;
    font-size: 2vh;
}

.project-card-description {
    margin-left: 4vh;
    margin-right: 4vh;
    white-space: pre-wrap;
    text-align: justify;
    margin-bottom: 4vh;
    font-size: 1.8vh;
}

.project-card-stack {
    margin-left: 4vh;
    margin-right: 4vh;
    margin-bottom: 3vh;
    line-height: 110%;
}

.project-card-stack-header {
    font-style: oblique;
    margin-bottom: 0.5vh;
}

.project-card-stack-item {
    font-size: 1.8vh;
    font-weight: bold;
    display: inline-block;
    border-radius: 2.5vh;
    background: #db5406;
    padding-inline: 1vh;
    margin-right: 1vh;
    margin-bottom: 0.5vh;
}

.project-card-button-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 4vh;
    margin-right: 4vh;
    text-align: center;
    margin-bottom: 5vh;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

.project-card-button {
    margin-bottom: 1.2vh;
    cursor: pointer;
    border-radius: 1.5vh;
    background: rgb(207, 187, 0);
    padding: 1vh;
    width: 80%;
    font-size: 2vh;
    font-weight: bold;
    outline: 0.1vh solid rgb(138, 138, 138);
}

a.project-card-button:link {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

a.project-card-button:visited {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

a.project-card-button:hover {
    background: rgb(175, 158, 2);
    text-decoration: none;
}


@media screen and (orientation:portrait) {
    .project-card {
        flex-direction: column;
    }

    .project-card-banner {
        flex: 1;
    }

    .project-card-info {
        flex: 3;
    }

    .project-card-category {
        font-size: 15px;
    }
}