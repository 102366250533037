.skills {
    height: 100vh;
    background-color: rgb(22, 104, 107);
    color: white;
    position: relative;
}

.empty-space-for-navbar {
    height: 10vh;
    top: 0;
}

.skills-wrapper {
    display: flex;
    height: 90vh;
    width: 50vw;
    transform: translateX(50%);
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}

.skills-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.skills-right {
    padding-left: 8%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.skill-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    padding-bottom: 5vh;
    padding-left: 5vh;
    font-size: 2.5vh;
}

.skill-section:hover h2 {
    color: rgb(236, 213, 0);
}

.skill-section:hover img {
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #525151);
}

.skill-section-name {
    flex: 2;
    padding-bottom: 1vh;
}

.skill-section-logos {
    flex: 8;
    display: flex;
    flex-direction: row;
}

.skill-img {
    filter: brightness(0) invert(1);
    height: 10vh;
    width: 10vh;
    padding-right: 1vh;
}

a:link {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:visited {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media screen and (orientation:portrait) {
    .skills-wrapper {
        /* flex-direction: column; */
        /* height: 50vh;
        width: 30vw; */
    }

    .skills-right {
        /* padding-left: 0; */
    }

    .skill-section {
        padding-bottom: 50px;
        padding-left: 10px;
        font-size: 10px;
    }

    .skill-img {
        filter: brightness(0) invert(1);
        height: 40px;
        width: 40px;
        padding-right: 10px;
    }
}