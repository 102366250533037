.career {
    height: 100vh;
    background-color: rgb(82, 32, 32);
    color: white;
    position: relative;
}

.empty-space-for-navbar {
    height: 10vh;
    top: 0;
}

.career-wrapper {
    display: flex;
    height: 90vh;
}

.career-timeline {
    position: absolute;
    width: 80%;
    height: 70vh;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #000000);
}

.career-card {
    margin-left: 0;
    margin-right: auto;
    font-size: 2vh;
}


.career-card-stack {
    padding-top: 2vh;
    padding-bottom: 1vh;
    line-height: 140%;
    align-items: end;
}

.career-card-stack-item {
    font-size: 1.8vh;
    font-weight: bold;
    display: inline-block;
    border-radius: 2.5vh;
    background: #0d9101;
    padding-inline: 1vh;
    margin-right: 1vh;
    margin-bottom: 0.5vh;
}

a:link {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:visited {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media screen and (orientation:portrait) {
    .career-timeline {
        width: 90%;
    }
}