.projects {
    height: 100vh;
    background-color: rgb(52, 51, 80);
    color: white;
    position: relative;
}

.empty-space-for-navbar {
    height: 10vh;
    top: 0;
}

.projects-wrapper {
    display: flex;
    height: 90vh;
}

.projects-carousel {
    position: absolute;
    width: 80%;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0.5vh 0.5vh 0.1vh #000000);
}

.projects-carousel-opener {
    position: relative;
    text-overflow: clip;
    white-space: nowrap;
    top: 40%;
    bottom: 50%;
}

.projects-title {
    color: rgb(236, 213, 0);
    font-size: 5vh;
}

.projects-subtitle {
    color: rgb(216, 216, 216);
    font-size: 3vh;
}

.projects-arrow {
    filter: brightness() invert(1);
    display: relative;
    max-width: 8%;
    max-height: 8%;
    width: auto;
    height: auto;
    transform: translate(60vh, -5vh) rotate(-35deg) ;
}

a:link {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:visited {
    color: rgb(203, 255, 15);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media screen and (orientation:portrait) {
    .projects-carousel-opener {
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
        top: 30%;
    }
}
